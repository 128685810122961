































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import MediaCard from "@/views/weplay-media/components/MediaCard.vue";
import PopDetail from "@/views/weplay-media/components/PopDetail.vue";
import { GameDataItem, GameVendorItem } from "@/types/global-state.type";
import { Popup, ImagePreview } from "vant";
import { done } from "nprogress";
import GlobalStateStore from "@/store/modules/global-state.store";
import { getModule } from "vuex-module-decorators";

@Component({
  components: {
    MediaCard,
    PopDetail,
    Popup,
  },
})
export default class WeplayMedia extends Vue {
  wideHigh: number | undefined;
  show = false;
  game_vendor_json: GameVendorItem[] = [];
  theNewData = [
    {
      title: "两天玩不够的游戏文化主题乐园，2021 WePlay文化展限时开售!",
      date: "2021年11月29日",
      id: "23411",
      img: "https://cdn.vgn.cn/static/welfare/%E9%A3%9E%E4%B9%A620211201-175717.png",
    },
    {
      title:
        "WePlay主舞台“CiGA×Fami通_电击游戏 - 游戏直⾯会”首位嘉宾公开！“超高校级剧本家“",
      date: "2021-11-30",
      id: "23441",
      img: "http://cdn.vgn.cn/admin/admin-cover_16382670019765.jpg",
    },
    {
      title: "WePlay海外游戏主题体验第三弹，来自韩国BIC几款不同风格的动作游戏",
      date: "2021-11-30",
      id: "23440",
      img: "http://cdn.vgn.cn/admin/admin-cover_16382668902938.png",
    },
    {
      title:
        "韩国独立游戏活动Indie Craft出展WePlay，在童话般的世界中一起展开冒险",
      date: "2021-11-30",
      id: "23439",
      img: "http://cdn.vgn.cn/admin/admin-cover_16382667796519.png",
    },
    {
      title: "日本独立游戏活动BitSummit出展WePlay，每一款游戏风格都很独特！",
      date: "2021-11-30",
      id: "23437",
      img: "http://cdn.vgn.cn/admin/admin-cover_16382668116644.png",
    },
  ];
  globalState = getModule(GlobalStateStore);

  initScriptGameData() {
    const script = document.createElement("script");
    script.src = "https://cdn.vgn.cn/static/game_vendor/game_vendor.js?t=2";
    document.querySelector("head")?.appendChild(script);
    const timer = setInterval(() => {
      // @ts-ignore
      if (window["game_vendor_json"]) {
        clearInterval(timer);
        // @ts-ignore
        this.game_vendor_json = window["game_vendor_json"];
      }
    }, 100);
  }
  toGetP() {
    location.href =
      "https://show.bilibili.com/platform/detail.html?msource=mall_piaowu_pc_3597_banner&id=55476&from=pwng-pc";
  }
  async created() {
    this.initScriptGameData();
    const { clientWidth, offsetWidth } = document.body;
    this.wideHigh = (clientWidth || offsetWidth) / 2 - 28;
    this.globalState.setLoginTip(false);
  }
  popData: any = {};
  openScreenshots(item: any) {
    ImagePreview([item]);
  }
  showPop(item: GameDataItem) {
    this.popData = item;
    this.show = true;
    console.log(this.game_vendor_json);
    this.$nextTick(() => {
      document.querySelectorAll(".game-intro img").forEach((el) =>
        el.addEventListener("click", (e) => {
          try {
            this.openScreenshots((e.target as any)?.src);
          } catch (e) {}
        })
      );
    });
  }
}
